
// Fonts
// @import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
// @import "variables";

// Bootstrap
// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.navbar {
    // margin-bottom: 45px;
}

// Bootstrap + Bootform Fixes
.help-block {
    color: #ccc;
}
.has-error {
    color: rgb(217, 83, 79);
}
.has-error .help-block {
    color: rgb(217, 83, 79);
}
.has-error input,
.has-error textarea,
.has-error input:focus {
    border-color: rgb(217, 83, 79) !important;
}

// Custmo Typography Classes
.text-muted-light {
    color: rgb(203, 199, 199);
}
.text-muted-extra-light {
    color: rgb(221, 221, 221);
}
.btn-fake {
    cursor: default;
}
